let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Marcus Edwards",
    "role": "Quantum Software Engineer",
    "linkedinId":"",
    "skypeid": "",
    "roleDescription": "",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/marcus-edwards-13154b111/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/comp-phys-marc?tab=repositories",
          "className":"fa fa-github"
        },
        {
          "name":"twitter",
          "url":"https://twitter.com/comp-phys-mark",
          "className":"fa fa-twitter"
        },
        {
          "name":"website",
          "url":"https://comp-phys-marc.github.io",
          "className":"fa fa-globe"
        }
      ],
    "aboutme":"My professional experiences include leadership in ambitious engineering endeavours, entrepreneurship in tech, and scientific research.",
    "address":"msedwards@uwaterloo.ca",
    "website":"https://comp-phys-marc.github.io/",
    "education":[
      {
        "UniversityName":"University of Waterloo",
        "specialization":"Master of Physics, Quantum Information Science",
        "MonthOfStarting":"September",
        "YearOfStarting":"2018",
        "MonthOfPassing":"December",
        "YearOfPassing":"2020",
        "Achievements":"At the Institute for Quantum Computing I performed research in the area of quantum software and hybrid quantum / classical algorithms."
      },
      {
        "UniversityName":"Wilfrid Laurier University",
        "specialization":"Bachelor of Science, Computer Science and Physics",
        "MonthOfStarting":"September",
        "YearOfStarting":"2014",
        "MonthOfPassing":"April",
        "YearOfPassing":"2018",
        "Achievements":"Computing and Computer Electronics is a program that combines computer science, electronics and mathematics. In this program I learned to solve problems using hardware and software co-design and embedded system solutions."
      }
    ],
    "software":[
      {
        "name": "Distributed Quantum Emulator",
        "affiliation": "Open Source Repository",
        "link": "https://github.com/comp-phys-marc/distributed-emulator",
        "linkName": "Code",
        "description": `This software comprises the work that was presented in the talk "Developing a Hybrid Methodology for Solving Quantum Problems" 
        in the 2019 Canada-America-Mexico Canadian Association of Physics Graduate Student Physics Conference, and was the software framework that I 
        used to complete experimental components of my Masters thesis at the University of Waterloo in 2020.`,
        "additionalInfoLink": "https://www.researchgate.net/publication/355362093_Developing_a_Hybrid_Methodology_for_Solving_Quantum_Problems",
        "additionalInfoName": "Poster"
      },
      {
        "name": "Continuous-Variable Quantum Circuit Drawer",
        "affiliation": "Xanadu Quantum Technologies Inc.",
        "link": "https://github.com/XanaduAI/strawberryfields/releases/tag/v0.10.0",
        "linkName": "Code",
        "description": `I contributed a software module to Xanadu's core StrawberryFields codebase
        which automatically converts a continuous-variable SF experiment into a representative 
        quantum citcuit in LaTeX.`,
        "additionalInfoLink": "https://strawberryfields.readthedocs.io/en/stable/code/sf_circuitdrawer.html",
        "additionalInfoName": "Documentation"
      },
      {
        "name": "Blackbird TypeScript",
        "affiliation": "Open Source Npm Module",
        "link": "https://www.npmjs.com/package/blackbird-ts",
        "linkName": "Code",
        "description": `BlackBird, the low-level programming language for continuous variable quantum computing, implemented in TypeScript.`,
        "additionalInfoLink": "https://github.com/comp-phys-marc/blackbird-ts",
        "additionalInfoName": "GitHub",
        "package": "blackbird-ts"
      },
      {
        "name": "OpenQASM TypeScript",
        "affiliation": "Open Source Npm Module",
        "link": "https://www.npmjs.com/package/qasm-ts",
        "linkName": "Code",
        "description": `QASM, the low-level programming language for quantum circuit specification, implemented in TypeScript. `,
        "additionalInfoLink": "https://github.com/comp-phys-marc/qasm-ts",
        "additionalInfoName": "GitHub",
        "package": "qasm-ts"
      },
      {
        "name": "QMASM TypeScript",
        "affiliation": "Open Source Npm Module",
        "link": "https://www.npmjs.com/package/qmasm-ts",
        "linkName": "Code",
        "description": `QMASM, the macro assembler for D-wave's quantum annealer, implemented in TypeScript. `,
        "additionalInfoLink": "https://github.com/comp-phys-marc/qmasm-ts",
        "additionalInfoName": "GitHub",
        "package": "qmasm-ts"
      }
    ],
    "research":[
      {
        "name": "Towards Practical Hybrid Quantum / Classical Computing",
        "affiliation": "Masters Thesis",
        "link": "http://hdl.handle.net/10012/16383",
        "linkName": "Thesis",
        "additionalInfoLink": "https://marcusedwards.me/files/defense.pdf",
        "additionalInfoName": "Defense",
        "description": `Quantum computing is in a critical phase where theoretical schemes and protocols are now being implemented in the real world for the first time. 
        Experimental implementations can help us solidify ideas, and can also complicate them.`
      },
      {
        "name": "A Review of Quantum and Hybrid Quantum / Classical Blockchain Protocols",
        "affiliation": "Literature Review Paper",
        "link": "https://link.springer.com/article/10.1007/s11128-020-02672-y",
        "linkName": "Paper",
        "description": `Quantum computing will not only cause challenges for blockchain, but can 
        also be harnessed to better implement parts of blockchain technologies including cryptocurrencies. 
        We review the work that has been done in the area of quantum blockchain and hybrid quantum-classical 
        blockchain technology and discuss open questions that remain.`
      },
      {
        "name": "Practical Quantum Algorithms",
        "affiliation": "University of Waterloo Three Minute Thesis Competition",
        "description": `The field of artificial intelligence is advancing rapidly in parallel with quantum computing.
        We are merging these fields together to build quantum-physics-inspired problem solving machines.`
      },
      {
        "name": "Quantum Annealers as Continuous Testing Automation Backends for Classical Web Code",
        "affiliation": "Cybersecurity Research Lab Research Exhibit 2019",
        "link": "https://comp-phys-marc.github.io/files/crl_poster.pdf",
        "linkName": "Poster",
        "description": ` We present a methodology and software package for verifying WebAssembly (WASM) web code 
        using quantum annealers. Executing a simulation of a WASM function on a quantum annealer enables fast 
        edge-case detection which can be used to verify the correctness of the function. The method benefits 
        from a speedup due to quantum tunneling in the annealer. This suggests an efficiency improvement over the 
        analogous classical sampling techniques being adopted by web software companies.`
      },
      {
        "name": "Quantum Information and Quantum Computing Special Session Chair",
        "affiliation": "V AMMCS Interdisciplinary International Conference 2019",
        "description": `I chaired the special session in quantum information and 
        quantum computing at the AMMCS Interdisciplinary International Conference 
        at Wilfrid Laurier University in 2019.`
      },
      {
        "name": "Robust General N User Quantum Secure Direct Communication via GHZ-like State",
        "affiliation": "V AMMCS Interdisciplinary International Conference 2019",
        "description": `We propose a secure and robust quantum network communication protocol based 
        on the controlled dense coding protocol. We perform security anlyses and experimental 
        demonstrations of the protocol.`,
      },
      {
        "name": "Developing a Hybrid Methodology for Solving Quantum Problems",
        "affiliation": "Canadian Association of Physics CAM Conference 2019",
        "link": "https://comp-phys-marc.github.io/files/poster_marcus_edwards_canada.pdf",
        "linkName": "Poster",
        "description": ` We contribute a framework for expressively implementing
        distributed quantum systems simulations in code. Our framework executes
        simulations using resources traditionally used for distributed cloud
        machine learning and data processing. We show how it can be used to
        analyze the performance of different simulation approaches and
        automatically suggest the best approaches to use for particular problems.`,
      },
      {
        "name": "Quantum Coin Toss Game Experiment",
        "affiliation": "TEDWomen 2018",
        "link": "https://www.ted.com/talks/shohini_ghose_a_beginner_s_guide_to_quantum_computing",
        "linkName": "Talk",
        "description": "An experiment using crowd-sourced data and the IBM Quatum Experience platform presented as a part of TED Fellow Dr. Ghose's TEDWomen 2018 talk."
      },
      {
        "name": "Controlled Algorithms on the IBMQX4",
        "affiliation": "Wilfrid Laurier University 2018 Student Thesis Poster Conference",
        "link": "https://comp-phys-marc.github.io/files/ControlledAlgorithms.pdf",
        "linkName": "Poster",
        "description": "This work	explores	the	effectiveness	of controlling	parties	in known quantum	teleportation and	dense	coding	algorithms.	Each algorithm	is	tested	on	the	IBMQX4 quantum	computer."
      },
      {
        "name": "Plantar Stimulus and Feedback Response DAQ",
        "affiliation": "Ontario Biomechanics Conference 2017",
        "description": `A re-design and
        re-build of an award-winning biomechanical data
        acquisition system presented at the
        2017 Ontario Biomechanics
        Conference.`,
      }
    ],
    "work":[
      {
        "CompanyName":"Photonic Inc.",
        "specialization":"Quantum Software Engineer",
        "MonthOfStarting":"January",
        "YearOfStarting":"2022",
        "MonthOfLeaving":"Present",
        "Achievements":"Quantum Software Engineers at Photonic are part of the Quantum Development team, providing the software “surround” for our quantum processors based on silicon colour centres."
      },
      {
        "CompanyName":"StudyForge",
        "specialization":"QA Strategist and Software Tester",
        "MonthOfStarting":"May",
        "YearOfStarting":"2021",
        "MonthOfLeaving":"October",
        "YearOfLeaving":"2021",
        "Achievements":"Defined and documented testing strategy, performed bug tracking, and wrote unit, integration and e2e tests. Also performed manual testing and refactored code for testability."
      },
      {
        "CompanyName":"rQadium",
        "specialization":"Consultant",
        "MonthOfStarting":"September",
        "YearOfStarting":"2019",
        "MonthOfLeaving":"April",
        "YearOfLeaving":"2021",
        "Achievements":"Provided consulting, research and development services to innovative information technology companies."
      },
      {
        "CompanyName":"University of Waterloo",
        "specialization":"Graduate Teaching Assistant",
        "MonthOfStarting":"September",
        "YearOfStarting":"2018",
        "MonthOfLeaving":"April",
        "YearOfLeaving":"2020",
        "Achievements":"During my graduate studies I was a graduate teaching assistant for the University of Waterloo's PHYS121 (Mechanics for Physicists), PHYS112 (Physics 2) and PHYS115 (Mechanics for Engineers) undergraduate courses."
      },
      {
        "CompanyName":"Ryerson Cybersecurity Research Lab",
        "specialization":"Highly Qualified Personnel",
        "MonthOfStarting":"June",
        "YearOfStarting":"2019",
        "MonthOfLeaving":"November",
        "YearOfLeaving":"2019",
        "Achievements":"The Cybersecurity Research Lab is a research lab at Ted Rogers School of Management at Ryerson University. As a researcher at CRL I focused on topics in quantum cryptography."
      },
      {
        "CompanyName":"SIGMA Development",
        "specialization":"Founder, Full Stack Developer",
        "MonthOfStarting":"October",
        "YearOfStarting":"2017",
        "MonthOfLeaving":"September",
        "YearOfLeaving":"2019",
        "Achievements":"Through SIGMA development I made professional level development available to startups on a budget by offering flexible plans for hardware, software and web development. I have shipped almost a dozen products for companies in Waterloo and the GTA."
      },
      {
        "CompanyName":"DelphX Inc.",
        "specialization":"Lead Software Developer",
        "MonthOfStarting":"February",
        "YearOfStarting":"2018",
        "MonthOfLeaving":"May",
        "YearOfLeaving":"2019",
        "Achievements":"I was the first developer employed by this blockchain fin-tech company that is now publicly traded. As a team lead I used Docker, Kubernetes, Microsoft Azure, RabbitMQ and Python microservices to build a blockchain based securities trading platform for the bond market."
      },
      {
        "CompanyName":"Leadme Inc.",
        "specialization":"Co-Founder, Full Stack Developer",
        "MonthOfStarting":"September",
        "YearOfStarting":"2016",
        "MonthOfLeaving":"August",
        "YearOfLeaving":"2018",
        "Achievements":"At Leadme we provided a low-profile technology that captured interactions with potential customers and provided rich metrics and data for marketing processes. We created a system that captured lead interactions through a wearable device. Data was made accessible through analytics and management portals available for Android and on the web."
      },
      {
        "CompanyName":"Wilfrid Laurier University Neuromechanics Lab",
        "specialization":"Technical Research Assistant",
        "MonthOfStarting":"December",
        "YearOfStarting":"2015",
        "MonthOfLeaving":"April",
        "YearOfLeaving":"2017",
        "Achievements":"As a Technical Research Assistant I developed software tools that interfaced with the force plates and OptoTrack laser positioning system used in the neuromechanics lab by graduate students to automate and simplify the analysis and interpretation of data. I also implemented upgrades to data acquisition systems and performed new hardware integration."
      },
      {
        "CompanyName":"BlackBerry",
        "specialization":"Network Automation Developer",
        "MonthOfStarting":"May",
        "YearOfStarting":"2017",
        "MonthOfLeaving":"August",
        "YearOfLeaving":"2017",
        "Achievements":"At BlackBerry I used Perl, Python and Ansible to automate the network management tasks performed in the company's network operations centers. I wrote secure software that interfaced with large databases and collected performance metrics."
      },
      {
        "CompanyName":"Connect Oasis Inc.",
        "specialization":"Jr. Software Developer",
        "MonthOfStarting":"May",
        "YearOfStarting":"2016",
        "MonthOfLeaving":"August",
        "YearOfLeaving":"2016",
        "Achievements":"At Connect Oasis Inc. I used Laravel, PHP, jQuery and React to create a cloud based data collection platform for retail chains."
      }
    ],
    "skillsDescription":"",
    "quantumSkills":[
      {
        "skillname":"QASM"
      },
      {
        "skillname":"QisKit"
      },
      {
        "skillname":"IBMQX"
      },
      {
        "skillname":"BlackBird"
      },
      {
        "skillname":"StrawberryFields"
      },
      {
        "skillname":"PennyLane"
      },
      {
        "skillname":"DWave"
      },
    ],
    "frontendSkills":[
      {
        "skillname":"JavaScript"
      },
      {
        "skillname":"React"
      },
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"SASS"
      },
      {
        "skillname":"Angular"
      },
      {
        "skillname":"TypeScript"
      },
      {
        "skillname":"Electron"
      },
    ],
    "backendSkills": [
      {
        "skillname":"Python"
      },
      {
        "skillname":"PHP"
      },
      {
        "skillname":"Laravel"
      },
      {
        "skillname":"Git"
      },
      {
        "skillname":"TensorFlow"
      },
      {
        "skillname":"Rust"
      },
      {
        "skillname":"C"
      },
      {
        "skillname":"Java"
      },
      {
        "skillname":"Perl"
      },
    ],
    "embeddedSkills": [
      {
        "skillname":"Arduino"
      },
      {
        "skillname":"Spice"
      },
      {
        "skillname":"MIPS"
      },
      {
        "skillname":"PIC"
      },
      {
        "skillname":"M68K"
      },
      {
        "skillname":"Processing"
      },
      {
        "skillname":"Assembly"
      },
      {
        "skillname":"VHDL"
      },
      {
        "skillname":"Mplab-X"
      },
      {
        "skillname":"Verilog"
      },
    ],
    "databaseSkills": [
      {
        "skillname":"MSSQL"
      },
      {
        "skillname":"PSQL"
      },
      {
        "skillname":"NoSQL"
      },
      {
        "skillname":"MySQL"
      },
    ],
    "distributedSkills": [
      {
        "skillname":"Docker"
      },
      {
        "skillname":"RabbitMQ"
      },
      {
        "skillname":"Kafka"
      },
      {
        "skillname":"Kubernetes"
      },
      {
        "skillname":"Redis"
      },
    ],
    "mobileSkills": [
      {
        "skillname":"React-Native"
      },
      {
        "skillname":"Cordova"
      },
      {
        "skillname":"Ionic"
      },
      {
        "skillname":"Android"
      },
      {
        "skillname":"XCode"
      },
    ],
    "courseSkills": [
      {
        "skillname":"Quantum-Computing"
      },
      {
        "skillname":"Quantum-Mechanics-1"
      },
      {
        "skillname":"Quantum-Mechanics-2"
      },
      {
        "skillname":"Directed-Research-Study-in-Physics"
      },
      {
        "skillname":"Introduction-to-Quantum-Information-Processing"
      },
      {
        "skillname":"Implementations-of-Quantum-Information-Processing"
      },
      {
        "skillname":"Graduate-Quantum-Mechanics"
      },
    ],
    "portfolio":[
      {
        "name":"Xanadu StrawberryFields",
        "description":"Strawberry Fields is a full-stack Python library for designing, simulating, and optimizing continuous variable (CV) quantum optical circuits.",
        "imgurl":"images/projects/xanadu.png",
        "link":"https://www.xanadu.ai/software/"
      },
      {
        "name":"Experiment for TED Presentation",
        "description":"Collected experimental data for TED Fellow Shohini Ghose's 2018 TED presentation.",
        "imgurl":"images/projects/ted.png",
      },
      {
        "name":"Quantum Coin Toss Game",
        "description":"An interactive implementation of the quantum coin toss challenge that pits the player against the IBMQX4 quantum computer.",
        "imgurl":"images/projects/coin.png",
      },
    ],
    "testimonials":[
      {
        "description":"Marcus has demonstrated that he is able to work on some of the most ambiguous problems I have ever seen, and provides good insights and new ideas. He also has demonstrated the ability to develop innovative software technologies, and has contributed to the design of many of the components that are part of the software.",
        "name":"Nicholas Lee-Hone, Quantum Software Engineer at Photonic Inc."
      },
      {
        "description":"Marcus is one of the most passionate and reliable people I have had the pleasure of working with. He always meets his deadlines, is a great communicator and is fun to work with. He has excellent technical skills and a proven ability to lead a project from start to finish. He has an incredibly wide skill-set that is always expanding because he is constantly learning new technologies and working on different projects.",
        "name":"Tom Robertson, Software Developer at Clearpath Inc."
      },
      {
        "description": "It was clear from the start that Marcus possessed an impressive breadth of experience and thirst for knowledge directly applicable across the full-stack spectrum of technologies we were already or about to start using. He quickly learned the domain space and related new technologies, and productively participated and collaborated in architectural design discussions of a complex financial trading platform with seasoned developer colleagues; he earned their respect. He actively took the lead and competently coordinated the UI design and development efforts by a third-party team with minimal direction and provided several demonstrable deliverables.",
        "name": "Nick Foisy, VP of Engineering at DelphX Inc."
      },
      {
        "description":"When we started out all we had was a concept; an idea. It felt like we were heading nowhere with all the freelance developers. Then Marcus Edwards and his Sigma Dev came along, and turned our dream of a fully fledged app into reality. Their ability to listen, their flexibility, their empathy all add up to make them the ideal team to work with.",
        "name":"Francis Xavier Ssempiira, CEO at Yata Technologies Inc."
      },
      {
        "description":"Marcus is a strong team player. He contributed greatly to the user interface of our application and showed himself to be very adept with React. He designed a SASS framework that has set us up for easy application styling. During the time he worked with us, he has been an active communicator, which proved to be useful in both ramping him up with the existing Geddy application, as well as help us progress with our UI work.",
        "name":"Aaron Liu & James White, Geddy Founders"
      },
      {
        "description":"Marcus is a highly skilled professional who can easily create user friendly and scalable applications using the latest technologies. His knowledge of all aspects of software development, from business decisions to system architecture to dev ops, is astonishing and is highly valued by myself and all members of our DelphX team. These abilities make Marcus a great team member who is capable of helping in all aspects of the organization. It's a pleasure working with you Marcus!",
        "name":"Chad Vance, Senior Software Developer at DelphX Inc."
      },
      {
        "description":"We	put Marcus	into	an	active	Laravel	project	and	put	demands	on	him	to	work	with	somewhat	complicated	         components	in	both	PHP	and	Javascript.	He	was	a	quick	learner	and	took good advantage of	available	         resources	with minimal	assistance	needed.	His	technical	skills,	creativity,	and	technical	agility	was well	         demonstrated	during	his time	here.	His	performance	was	impressive	and	was	instrumental	in	helping	us	         deliver	our	project	in	demanding	conditions. If	you	are	considering	Marcus	for	a	development	positon	I	         believe	he	would	be	a	good	fit.",
        "name":"Brendan Farr-Gaynor, Managing Partner at Olio Digital Labs"
      }
    ]
  }
  
  export default resumeData;