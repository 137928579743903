import React, { Component } from 'react';
export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="about">
         <div className="row">

            <div className="three columns">

               <img className="profile-pic"  src="images/profilepic.jpg" alt="" />

            </div>

            <div className="nine columns main-col">

               <h2>About Me</h2>
               <p>
               {
                 resumeData.aboutme
               }
               </p>

               <p>
                  To hear me describe my interests in quantum computing research and technology, listen to the episode of the Practical AI podcast below!
               </p>

               <audio data-theme="night" data-src="https://changelog.com/practicalai/43/embed" src="https://cdn.changelog.com/uploads/practicalai/43/practical-ai-43.mp3" preload="none" class="changelog-episode" controls></audio><p><a href="https://changelog.com/practicalai/43">Practical AI 43: When AI meets quantum mechanics</a> – Listen on <a href="https://changelog.com/">Changelog.com</a></p><script async src="//cdn.changelog.com/embed.js"></script>

               <div className="row">

                  <div className="columns contact-details">

                  <h2>Contact Details</h2>
                  <p className="address">
                     <span>
                        <a href={`mailto:${resumeData.address}`}>{resumeData.address}</a>
                     </span>
                     <br></br>
                    <span>
                       <a href={resumeData.website}>{resumeData.website}</a>
                     </span>
       				</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
    );
  }
}