let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Marcus Edwards",
    "role": "Quantum Software Engineer",
    "linkedinId":"",
    "skypeid": "",
    "roleDescription": "",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/marcus-edwards-13154b111/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"https://github.com/comp-phys-marc?tab=repositories",
          "className":"fa fa-github"
        },
        {
          "name":"twitter",
          "url":"https://twitter.com/comp-phys-mark",
          "className":"fa fa-twitter"
        },
        {
          "name":"website",
          "url":"https://comp-phys-marc.github.io",
          "className":"fa fa-globe"
        }
      ],
    "aboutme":"My professional experiences include leadership in ambitious engineering endeavours, entrepreneurship in tech, and scientific research.",
    "address":"msedwards@ece.ubc.ca",
    "website":"https://comp-phys-marc.github.io/",
    "education":[
      {
        "UniversityName":"University of British Columbia",
        "specialization":"Doctor of Philosophy, Electrical and Computer Engineering",
        "MonthOfStarting":"May",
        "YearOfStarting":"2023",
        "MonthOfPassing":"April",
        "YearOfPassing":"2028",
        "Achievements":"The Quantum Science and Technology Lab at the University of British Columbia develops controllable solid-state quantum systems."
      },
      {
        "UniversityName":"Simon Fraser University",
        "specialization":"Visiting Student on Grad Exchange through the Western Dean's Agreement",
        "MonthOfStarting":"January",
        "YearOfStarting":"2025",
        "MonthOfPassing":"April",
        "YearOfPassing":"2025",
        "Achievements":"Studying quantum circuits and compilers."
      },
      {
        "UniversityName":"University of Victoria",
        "specialization":"Visiting Student on Grad Exchange through the Western Dean's Agreement",
        "MonthOfStarting":"September",
        "YearOfStarting":"2024",
        "MonthOfPassing":"December",
        "YearOfPassing":"2024",
        "Achievements":"Studied topics in condensed matter, quantum devices."
      },
      {
        "UniversityName":"Wilfrid Laurier University",
        "specialization":"Bachelor of Science, Computer Science and Physics",
        "MonthOfStarting":"September",
        "YearOfStarting":"2014",
        "MonthOfPassing":"April",
        "YearOfPassing":"2018",
        "Achievements":"Computing and Computer Electronics is a program that combines computer science, electronics and mathematics. In this program I learned to solve problems using hardware and software co-design and embedded system solutions."
      }
    ],
    "software":[
      {
        "name": "Efficient Quantum State Tomography",
        "affiliation": "Open Source Repository",
        "link": "https://github.com/comp-phys-marc/QHack2024IRL",
        "linkName": "Code",
        "description": `This was my first-place Xanadu QHack 2024 IRL Hackathon project. Cramer et. al. provide an algorithm for approximating an arbitrary state with a matrix product state which may be used in conjunction with standard quantum state tomography. They call this a Modified Singular Value Thresholding algorithm. I implemented this algorithm as well as basic state tomography in this notebook and showed how they may be used together.`
      },
      {
        "name": "Quantum Circuit Classifier",
        "affiliation": "Open Source Repository",
        "link": "https://github.com/comp-phys-marc/circuit-parsers",
        "linkName": "Code",
        "description": `A machine learning based software tool that classifies images of quantum circuits and returns the QASM that would define the circuit in code.`
      },
      {
        "name": "Heterostructure Field Effect Transistor Hallbars",
        "affiliation": "Nanoelectronic Hardware",
        "link": "https://www.linkedin.com/in/marcus-edwards-98ab8a220/details/projects/882287779/multiple-media-viewer/?profileId=ACoAADe0FPoBxzBVDgIC94a7gIwTRg5wB2m5miw&treasuryMediaId=1738196560063",
        "linkName": "Microscope Images",
        "description": `Hallbars can be used to measure material properties that are important to consider when selecting them for use as substrates on which we fabricate quantum devices.`
      },
      {
        "name": "Quantum Device Carrier PCB",
        "affiliation": "Open Source Hardware",
        "link": "https://github.com/Quantum-Science-and-Technology-Lab/carrier_PCB",
        "linkName": "Design Files",
        "description": `The sample carrier PCB is required to interface on-chip quantum devices with control electronics. The PCB is a 4-layer board with FR4 dielectric, 48 DC lines and 8 RF lines.`
      },
      {
        "name": "Labber Experimental Physics Wrapper",
        "affiliation": "Open Source Repository",
        "link": "https://github.com/Quantum-Science-and-Technology-Lab/labber-wrapper",
        "linkName": "Code",
        "description": `A library of software utilities and experimental procedures built on Labber. Each experiment is encapsulated in an importable function. Some come with additional public utilities such as classes and functions that can be imported alongside an experiment.`
      },
      {
        "name": "Canadian Quantum Cup",
        "affiliation": "Open Source Repository",
        "link": "https://github.com/comp-phys-marc/canadian-quantum-cup",
        "linkName": "Code",
        "description": `Our solutions to the Xanadu Canadian Quantum Cup challenges that placed us in the top three of all the participating Canadian universities.`
      },
      {
        "name": "Distributed Quantum Emulator",
        "affiliation": "Open Source Repository",
        "link": "https://github.com/comp-phys-marc/distributed-emulator",
        "linkName": "Code",
        "description": `This software comprises the work that was presented in the talk "Developing a Hybrid Methodology for Solving Quantum Problems" 
        in the 2019 Canada-America-Mexico Canadian Association of Physics Graduate Student Physics Conference, and was the software framework that I 
        used to complete experimental components of my Masters thesis at the University of Waterloo in 2020.`,
        "additionalInfoLink": "https://www.researchgate.net/publication/355362093_Developing_a_Hybrid_Methodology_for_Solving_Quantum_Problems",
        "additionalInfoName": "Poster"
      },
      {
        "name": "Continuous-Variable Quantum Circuit Drawer",
        "affiliation": "Xanadu Quantum Technologies Inc.",
        "link": "https://github.com/XanaduAI/strawberryfields/releases/tag/v0.10.0",
        "linkName": "Code",
        "description": `I contributed a software module to Xanadu's core StrawberryFields codebase
        which automatically converts a continuous-variable SF experiment into a representative 
        quantum citcuit in LaTeX.`,
        "additionalInfoLink": "https://strawberryfields.readthedocs.io/en/stable/code/sf_circuitdrawer.html",
        "additionalInfoName": "Documentation"
      },
      {
        "name": "Language Processing Tools",
         "affiliation": "Open Source Repository",
         "link": "https://github.com/comp-phys-marc/language-processing-tools",
         "linkName": "Code",
         "description": `Each of these utilities allow a user to perform a particular file / text processing action. The tools allow a user to download and pre-process files, train and manage NLP models on those files, and programmatically compare file contents from any stage of the workflow.`
      },
      {
        "name": "Quantum Rust",
        "affiliation": "Open Source Repository",
        "link": "https://github.com/comp-phys-marc/quantum-rs",
        "linkName": "Code",
        "description": `A simple Rust based simulator of pure-state quantum computation.`
      },
      {
        "name": "Blackbird TypeScript",
        "affiliation": "Open Source Npm Module",
        "link": "https://www.npmjs.com/package/blackbird-ts",
        "linkName": "Code",
        "description": `BlackBird, the low-level programming language for continuous variable quantum computing, implemented in TypeScript.`,
        "additionalInfoLink": "https://github.com/comp-phys-marc/blackbird-ts",
        "additionalInfoName": "GitHub",
        "package": "blackbird-ts"
      },
      {
        "name": "OpenQASM TypeScript",
        "affiliation": "Open Source Npm Module",
        "link": "https://www.npmjs.com/package/qasm-ts",
        "linkName": "Code",
        "description": `QASM, the low-level programming language for quantum circuit specification, implemented in TypeScript. `,
        "additionalInfoLink": "https://github.com/comp-phys-marc/qasm-ts",
        "additionalInfoName": "GitHub",
        "package": "qasm-ts"
      },
      {
        "name": "QMASM TypeScript",
        "affiliation": "Open Source Npm Module",
        "link": "https://www.npmjs.com/package/qmasm-ts",
        "linkName": "Code",
        "description": `QMASM, the macro assembler for D-wave's quantum annealer, implemented in TypeScript. `,
        "additionalInfoLink": "https://github.com/comp-phys-marc/qmasm-ts",
        "additionalInfoName": "GitHub",
        "package": "qmasm-ts"
      }
    ],
    "research":[
      {
        "name": "Deep Learning Methods for Learning Pauli Errors and Reconstructing Density and Process Matrices",
        "affiliation": "ArXiv Pre-print",
        "link": "#",
        "linkName": "Article",
        "description": `We implement several alternative approaches to encoding quantum information in matrix representations and leverage these representations to reconstruct density matrices as well as process matrices describing the noisy dynamics of quantum channels using parameters (Pauli error rates) learned by machine learning. We test the hypothesis that our machine learning techniques can perform dimensionality and input reduction to solve for more efficient approaches to learning the Pauli noise in a specific circuit by creating a benchmark dataset with standard methods and comparing the results from our new neural network implementation against it. We find that using a new method of evolving the quantum state by updating a graph Laplacian is more efficient than traditional left and right multiplication of density matrices by unitaries and use this to reconstruct the final quantum state corresponding to circuits with learned error rates. We find convergence for the Pauli error rates, and our approach with process matrix reconstruction in the training loop generalizes well, with a mean loss dropping from the original 1.03 to 0.4.`
        "additionalInfoLink": "https://github.com/comp-phys-marc/quantum_channel_characterization",
        "additionalInfoName": "GitHub",
      },
      
      {
        "name": "Enabling the Verification and Formalization of Hybrid Quantum-Classical Computing with OpenQASM 3.0 compatible QASM-TS 2.0",
        "affiliation": "ArXiv Pre-print",
        "link": "https://arxiv.org/abs/2412.12578",
        "linkName": "Article",
        "description": `The unique features of the hybrid quantum-classical computing model implied by the specification of OpenQASM 3.0 motivate new approaches to quantum program verification. We implement a typed QASM 3.0 parser in TypeScript to enable implementations of verification and validation software, compilers and more. We also propose that a formal treatment of OpenQASM 3.0's type system in type theory notation may further facilitate formal verification. We highlight advancements in hybrid quantum-classical computing since the Quantum Hoare Logic to this end.`
      },
      {
        "name": "Towards Readout-Aware Layout Synthesis for Spin Qubit Systems with Double Quantum Dot Readouts",
        "affiliation": "Conference Paper",
        "link": "https://www.computer.org/csdl/proceedings-article/qce/2024/413702a589/23oqvyvLv2g",
        "linkName": "Paper",
        "description": `A quantum compiler is required to run a quantum circuit on a quantum computer, similar to how classical compilers are required to run programs on classical computers. Spin qubits are a promising candidate for scalable quantum computers. General-purpose quantum compilers can be used for spin qubit devices, however they do not consider additional constraints imposed due to Double-Quantum-Dot (DQD) readout. For DQD readout, when a qubit in an algorithm is measured, the compiler must remap and re-route it to be adjacent to a special-purpose readout qubit, which is preserved in a known state. Moreover, this should be done in a way that minimizes overhead and maximizes the fidelity, in particular due to noise in the readout process. This work formulates readout constraints to extend SMT (Satisfiability Modulo Theory)-based layout synthesis techniques proposed in [1] to spin qubit architectures. We define a metric, readout depth, to quantify the overheads incurred. Preliminary results, obtained from benchmarking GHZ and variational quantum eigensolver circuits on architectures with grid topologies, highlight the algorithmic tradeoffs that arise. We expect ongoing work on scaling up the methods to a larger set of algorithms will help inform the decisions of spin qubit hardware designers.`
      },
      {
        "name": "Three Quantum Programming Language Parser Implementations for the Web",
        "affiliation": "ArXiv Pre-print",
        "link": "https://arxiv.org/abs/2310.10802",
        "linkName": "Article",
        "description": `IBM has developed a quantum assembly (QASM) language particular to gate model quantum computing since 2017 [CBSG17]. Version 3.0 which adds timing, pulse control, and gate modifiers is currently undergoing finalization in 2023 [CJA+21]. In a similar vein, Pakin of Los Alamos National Laboratory published a quantum macro assembler (QMASM) for D-Wave quantum annealers in 2016 [Pak16]. This assembler specifically targets quantum annealers like D-Wave's. A comparable technology that targets continuous-variable (CV) quantum computing is the Blackbird language developed by Xanadu since 2018 [KIQ+19]. We implement parsers for each of these languages in TypeScript with a singular approach. In the cases of Blackbird and QMASM these are the first parser implementations that are web compatible and so bring these languages to a new audience and to new runtimes. This makes the parsing and execution of QMASM, QASM and Blackbird possible in web and mobile environments that don't have access to heavy compile toolchains, enabling adoption and scientific research.`
      },
      {
        "name": "Designing a Hybrid Digital/Analog Quantum Physics Emulator as Open Hardware",
        "affiliation": "ArXiv Pre-print",
        "link": "https://arxiv.org/abs/2302.00821",
        "linkName": "Article",
        "description": `One of the most exciting quantum emulation [1] breakthroughs was the first analog signal-based emulation of a universal quantum computer [2]. This yielded a very interesting paper, but no practical use - even for theorists. The reason for this was that a signal duration of the approximate age of the universe (13.77 billion years) could accommodate only about 95 qubits. We propose a new scheme with the following properties: 1) a pair of oscillators or sinusioidal wave sources must be sufficient to emulate n superimposed states with the ability to be identifiably mixed or entangled, 2) the time required to perform a measurement of a state must not scale poorly with the complexity of the state, 3) a fixed set of hardware components must be sufficient to emulate a system of a significant number of qubits, and 4) at least as much must be knowable about an emulated quantum state as is expected to be measurable in a theoretical quantum computing system. We achieve a design whose time complexity scales favourably based on a new method of encoding quantum information into classical signals, but only anticipate the feasibility of encodings of up to 20 qubits with modern electrical hardware.`
      },
      {
        "name": "Quantum Honest Byzantine Agreement as a Distributed Quantum Algorithm",
        "affiliation": "ArXiv Pre-print",
        "link": "https://arxiv.org/abs/2301.02944",
        "linkName": "Article",
        "description": `We suggest that the Quantum Honest Byzantine Agreement (QHBA) protocol [1] essentially reduces consensus to coincidence. The volume of coincidence is the parameter that drives a receiver to echo its input. A lack of coincidence results in no output from a receiver. This is a similar mechanism therefore to the learning mechanism in cognitive modular neural architectures like Haikonen's architecture [2]. We introduce a simple feedback mechanism and quantum neuron to realize a hybrid quantum / classical machine learning network of simple nodes.`
      },
      {
        "name": "Machine Learning, Quantum Machine Learning and Consciousness — Scientifically and Philosophically",
        "affiliation": "Medium Article",
        "link": "https://medium.com/@marcusedwards-20301/machine-learning-quantum-machine-learning-and-consciousness-scientifically-3b44a0bb15a2",
        "linkName": "Article",
        "description": `Incomplete information plays an important role in quantum information, and artificial intelligence. However, the role is significantly different in each. In quantum information, the quantum uncertainty principle is respected. In classical probability, an uncertainty principle also exists, but with fundamentally different mechanics.`
      },
      {
        "name": "Building A Cloud-Microservices-Based Execution Environment for Hybrid Quantum-Classical Algorithms",
        "affiliation": "Medium Article",
        "link": "https://medium.com/@marcusedwards-20301/building-a-cloud-microservices-based-execution-environment-for-hybrid-quantum-classical-algorithms-ee8b5a18db58",
        "linkName": "Article",
        "description": `I rolled my own interoperable quantum physics simulation environment to help me compare simulation backends and learn quantum physics. This was what I built.`
      },
      {
        "name": "Artificial Intelligence-Based Personalized Financial Recommendation Assistant System and Method",
        "affiliation": "US Patent",
        "link": "https://patents.google.com/patent/US20210303973A1/en",
        "linkName": "Patent",
        "description": `Provided are a computer system and method for generating and providing intelligent recommendations using artificial intelligence (“AI”). The system includes a memory for storing user feedback data, user resource data, and user goal data, and a processor in communication with the memory. The processor is configured to execute a first AI model for user interface (“UI”) effectiveness optimization, a second AI model for transaction optimization, a model mapping mod ule configured to implement a functional mapping between the first Al model and the second AI model through which the first AI model and second Al model communicate and mutually update each other, and a user interface generator module for generating a user interface for outputting the intelligent recommendations and receiving the user feed back data.`
      },
      {
        "name": "A Review and Discussion of Variational Quantum Anomaly Detection",
        "affiliation": "QuBytes Digest Article",
        "link": "https://qubytes.org/2021/07/08/a-review-and-discussion-of-variational-quantum-anomaly-detection/",
        "linkName": "Article",
        "description": `One of the first motivations for building quantum computers was the potential to use them for quantum simulations: the controlled simulation of complex quantum mechanical systems. An important part of understanding a complex system of this sort is to know its phase diagram. The recent emergence of QML (Quantum Machine Learning) [1] involves – in one of its facets – the application of machine learning techniques to the problem of quantum control. In this review, we summarize and discuss a recent publication that introduced a new QML algorithm called VQAD (Variational Quantum Anomaly Detection) [2] to extract a quantum system’s phase diagram without any prior knowledge about the quantum device.`
      },
      {
        "name": "Computer System and Computer-Implemented Method for Creating a Savings Plan for Specific Purchases",
        "affiliation": "US Patent",
        "link": "https://patents.google.com/patent/US20210201397A1/en",
        "linkName": "Patent",
        "description": `Provided is a computer system and method for automatically electronically transferring funds. The system includes a computer display device displaying a user interface configured to provide the customer with a computer input device to create a savings plan to purchase an item with an indicated price. The system provides that the savings plan is based on a series of electronic fund transfers into a savings account to accumulate electronic funds in the savings account equaling the indicated price. The system also includes a computer server which includes a computer processor configured to determine that the customer does not have sufficient funds and sufficient credit to electronically transfer funds equaling the indicated price. The server also includes a computer readable memory with stored instructions to initiate a purchasing action to purchase the item for the customer when the savings account has sufficient funds to purchase the item.`
      },
      {
        "name": "Systems Methods and Devices for Increasing Security when Using Smartcards",
        "affiliation": "US Patent",
        "link": "https://patents.google.com/patent/US20210133725A1/en",
        "linkName": "Patent",
        "description": `Provided are systems, methods and devices for increasing security in a financial transaction when using a smartcard. The system includes a smartcard for payment in a financial transaction, the smartcard having a memory for storing a first actuation sequence for confirming a user's identity; wherein the smartcard has a checking module that authorizes the transaction only when a second actuation sequence is identical to the first actuation sequence stored in the memory; a point of sale terminal for recording payment details from the smartcard once the financial transaction is authorized and transmitting a request for payment through a network to a financial institution according to the payment details; and a financial institution authorizing computer for allowing recording of an actuation sequence on the smartcard and making payment to the owner of the point of sale terminal once the transaction is authorized, according to the payment details.`
      },
      {
        "name": "Towards Practical Hybrid Quantum / Classical Computing",
        "affiliation": "Masters Thesis",
        "link": "http://hdl.handle.net/10012/16383",
        "linkName": "Thesis",
        "additionalInfoLink": "https://marcusedwards.me/files/defense.pdf",
        "additionalInfoName": "Defense",
        "description": `Quantum computing is in a critical phase where theoretical schemes and protocols are now being implemented in the real world for the first time. 
        Experimental implementations can help us solidify ideas, and can also complicate them.`
      },
      {
        "name": "A Review of Quantum and Hybrid Quantum / Classical Blockchain Protocols",
        "affiliation": "Literature Review Paper",
        "link": "https://link.springer.com/article/10.1007/s11128-020-02672-y",
        "linkName": "Paper",
        "description": `Quantum computing will not only cause challenges for blockchain, but can 
        also be harnessed to better implement parts of blockchain technologies including cryptocurrencies. 
        We review the work that has been done in the area of quantum blockchain and hybrid quantum-classical 
        blockchain technology and discuss open questions that remain.`
      },
      {
        "name": "Practical Quantum Algorithms",
        "affiliation": "University of Waterloo Three Minute Thesis Competition",
        "description": `The field of artificial intelligence is advancing rapidly in parallel with quantum computing.
        We are merging these fields together to build quantum-physics-inspired problem solving machines.`
      },
      {
        "name": "Quantum Annealers as Continuous Testing Automation Backends for Classical Web Code",
        "affiliation": "Cybersecurity Research Lab Research Exhibit 2019",
        "link": "https://comp-phys-marc.github.io/files/crl_poster.pdf",
        "linkName": "Poster",
        "description": ` We present a methodology and software package for verifying WebAssembly (WASM) web code 
        using quantum annealers. Executing a simulation of a WASM function on a quantum annealer enables fast 
        edge-case detection which can be used to verify the correctness of the function. The method benefits 
        from a speedup due to quantum tunneling in the annealer. This suggests an efficiency improvement over the 
        analogous classical sampling techniques being adopted by web software companies.`
      },
      {
        "name": "Quantum Information and Quantum Computing Special Session Chair",
        "affiliation": "V AMMCS Interdisciplinary International Conference 2019",
        "description": `I chaired the special session in quantum information and 
        quantum computing at the AMMCS Interdisciplinary International Conference 
        at Wilfrid Laurier University in 2019.`
      },
      {
        "name": "Robust General N User Quantum Secure Direct Communication via GHZ-like State",
        "affiliation": "V AMMCS Interdisciplinary International Conference 2019",
        "description": `We propose a secure and robust quantum network communication protocol based 
        on the controlled dense coding protocol. We perform security anlyses and experimental 
        demonstrations of the protocol.`,
      },
      {
        "name": "Developing a Hybrid Methodology for Solving Quantum Problems",
        "affiliation": "Canadian Association of Physics CAM Conference 2019",
        "link": "https://comp-phys-marc.github.io/files/poster_marcus_edwards_canada.pdf",
        "linkName": "Poster",
        "description": ` We contribute a framework for expressively implementing
        distributed quantum systems simulations in code. Our framework executes
        simulations using resources traditionally used for distributed cloud
        machine learning and data processing. We show how it can be used to
        analyze the performance of different simulation approaches and
        automatically suggest the best approaches to use for particular problems.`,
      },
      {
        "name": "Quantum Coin Toss Game Experiment",
        "affiliation": "TEDWomen 2018",
        "link": "https://www.ted.com/talks/shohini_ghose_a_beginner_s_guide_to_quantum_computing",
        "linkName": "Talk",
        "description": "An experiment using crowd-sourced data and the IBM Quatum Experience platform presented as a part of TED Fellow Dr. Ghose's TEDWomen 2018 talk."
      },
      {
        "name": "Controlled Algorithms on the IBMQX4",
        "affiliation": "Wilfrid Laurier University 2018 Student Thesis Poster Conference",
        "link": "https://comp-phys-marc.github.io/files/ControlledAlgorithms.pdf",
        "linkName": "Poster",
        "description": "This work	explores	the	effectiveness	of controlling	parties	in known quantum	teleportation and	dense	coding	algorithms.	Each algorithm	is	tested	on	the	IBMQX4 quantum	computer."
      },
      {
        "name": "Plantar Stimulus and Feedback Response DAQ",
        "affiliation": "Ontario Biomechanics Conference 2017",
        "description": `A re-design and
        re-build of an award-winning biomechanical data
        acquisition system presented at the
        2017 Ontario Biomechanics
        Conference.`,
      }
    ],
    "work":[
      {
        "CompanyName":"University of British Columbia",
        "specialization":"Teaching Assistant",
        "MonthOfStarting":"September",
        "YearOfStarting":"2023",
        "MonthOfLeaving":"April",
        "YearOfLeaving":"2024",
        "Achievements":"TA for Blockchain and Distributed Ledger Technology graduate seminar course, Blockchain Software Engineering graduate course."
      },
      {
        "CompanyName":"Photonic Inc.",
        "specialization":"Quantum Software Engineer",
        "MonthOfStarting":"January",
        "YearOfStarting":"2022",
        "MonthOfLeaving":"September",
        "YearOfLeaving":"2022",
        "Achievements":"Quantum Software Engineers at Photonic are part of the Quantum Development team, providing the software “surround” for our quantum processors based on silicon colour centres."
      },
      {
        "CompanyName":"StudyForge",
        "specialization":"QA Strategist and Software Tester",
        "MonthOfStarting":"May",
        "YearOfStarting":"2021",
        "MonthOfLeaving":"October",
        "YearOfLeaving":"2021",
        "Achievements":"Defined and documented testing strategy, performed bug tracking, and wrote unit, integration and e2e tests. Also performed manual testing and refactored code for testability."
      },
      {
        "CompanyName":"rQadium",
        "specialization":"R&D Consultant and Founder",
        "MonthOfStarting":"September",
        "YearOfStarting":"2019",
        "MonthOfLeaving":"April",
        "YearOfLeaving":"2021",
        "Achievements":"Provided consulting, research and development services to innovative information technology companies in spaces including fin-tech, health-tech and quantum computing."
      },
      {
        "CompanyName":"University of Waterloo",
        "specialization":"Graduate Teaching Assistant",
        "MonthOfStarting":"September",
        "YearOfStarting":"2018",
        "MonthOfLeaving":"April",
        "YearOfLeaving":"2020",
        "Achievements":"During my graduate studies I was a graduate teaching assistant for the University of Waterloo's PHYS121 (Mechanics for Physicists), PHYS112 (Physics 2) and PHYS115 (Mechanics for Engineers) undergraduate courses."
      },
      {
        "CompanyName":"Ryerson Cybersecurity Research Lab",
        "specialization":"Highly Qualified Personnel",
        "MonthOfStarting":"June",
        "YearOfStarting":"2019",
        "MonthOfLeaving":"November",
        "YearOfLeaving":"2019",
        "Achievements":"The Cybersecurity Research Lab is a research lab at Ted Rogers School of Management at Ryerson University. As a researcher at CRL I focused on topics in quantum cryptography."
      },
      {
        "CompanyName":"SIGMA Development",
        "specialization":"Founder, Full Stack Developer",
        "MonthOfStarting":"October",
        "YearOfStarting":"2017",
        "MonthOfLeaving":"September",
        "YearOfLeaving":"2019",
        "Achievements":"Through SIGMA development I made professional level development available to startups on a budget by offering flexible plans for hardware, software and web development. I have shipped almost a dozen products for companies in Waterloo and the GTA."
      },
      {
        "CompanyName":"DelphX Inc.",
        "specialization":"Lead Software Developer",
        "MonthOfStarting":"February",
        "YearOfStarting":"2018",
        "MonthOfLeaving":"May",
        "YearOfLeaving":"2019",
        "Achievements":"I was the first developer employed by this blockchain fin-tech company that is now publicly traded. As a team lead I used Docker, Kubernetes, Microsoft Azure, RabbitMQ and Python microservices to build a blockchain based securities trading platform for the bond market."
      },
      {
        "CompanyName":"Leadme Inc.",
        "specialization":"Co-Founder, Full Stack Developer",
        "MonthOfStarting":"September",
        "YearOfStarting":"2016",
        "MonthOfLeaving":"August",
        "YearOfLeaving":"2018",
        "Achievements":"At Leadme we provided a low-profile technology that captured interactions with potential customers and provided rich metrics and data for marketing processes. We created a system that captured lead interactions through a wearable device. Data was made accessible through analytics and management portals available for Android and on the web."
      },
      {
        "CompanyName":"Wilfrid Laurier University Neuromechanics Lab",
        "specialization":"Technical Research Assistant",
        "MonthOfStarting":"December",
        "YearOfStarting":"2015",
        "MonthOfLeaving":"April",
        "YearOfLeaving":"2017",
        "Achievements":"As a Technical Research Assistant I developed software tools that interfaced with the force plates and OptoTrack laser positioning system used in the neuromechanics lab by graduate students to automate and simplify the analysis and interpretation of data. I also implemented upgrades to data acquisition systems and performed new hardware integration."
      },
      {
        "CompanyName":"BlackBerry",
        "specialization":"Network Automation Developer",
        "MonthOfStarting":"May",
        "YearOfStarting":"2017",
        "MonthOfLeaving":"August",
        "YearOfLeaving":"2017",
        "Achievements":"At BlackBerry I used Perl, Python and Ansible to automate the network management tasks performed in the company's network operations centers. I wrote secure software that interfaced with large databases and collected performance metrics."
      },
      {
        "CompanyName":"Connect Oasis Inc.",
        "specialization":"Jr. Software Developer",
        "MonthOfStarting":"May",
        "YearOfStarting":"2016",
        "MonthOfLeaving":"August",
        "YearOfLeaving":"2016",
        "Achievements":"At Connect Oasis Inc. I used Laravel, PHP, jQuery and React to create a cloud based data collection platform for retail chains."
      }
    ],
    "skillsDescription":"",
    "quantumSkills":[
      {
        "skillname":"QASM"
      },
      {
        "skillname":"QisKit"
      },
      {
        "skillname":"IBMQX"
      },
      {
        "skillname":"BlackBird"
      },
      {
        "skillname":"StrawberryFields"
      },
      {
        "skillname":"PennyLane"
      },
      {
        "skillname":"DWave"
      },
    ],
    "frontendSkills":[
      {
        "skillname":"JavaScript"
      },
      {
        "skillname":"React"
      },
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"SASS"
      },
      {
        "skillname":"Angular"
      },
      {
        "skillname":"TypeScript"
      },
      {
        "skillname":"Electron"
      },
    ],
    "backendSkills": [
      {
        "skillname":"Python"
      },
      {
        "skillname":"PHP"
      },
      {
        "skillname":"Laravel"
      },
      {
        "skillname":"Git"
      },
      {
        "skillname":"TensorFlow"
      },
      {
        "skillname":"Rust"
      },
      {
        "skillname":"C"
      },
      {
        "skillname":"Java"
      },
      {
        "skillname":"Perl"
      },
    ],
    "embeddedSkills": [
      {
        "skillname":"Arduino"
      },
      {
        "skillname":"Spice"
      },
      {
        "skillname":"MIPS"
      },
      {
        "skillname":"PIC"
      },
      {
        "skillname":"M68K"
      },
      {
        "skillname":"Processing"
      },
      {
        "skillname":"Assembly"
      },
      {
        "skillname":"VHDL"
      },
      {
        "skillname":"Mplab-X"
      },
      {
        "skillname":"Verilog"
      },
    ],
    "databaseSkills": [
      {
        "skillname":"MSSQL"
      },
      {
        "skillname":"PSQL"
      },
      {
        "skillname":"NoSQL"
      },
      {
        "skillname":"MySQL"
      },
    ],
    "distributedSkills": [
      {
        "skillname":"Docker"
      },
      {
        "skillname":"RabbitMQ"
      },
      {
        "skillname":"Kafka"
      },
      {
        "skillname":"Kubernetes"
      },
      {
        "skillname":"Redis"
      },
    ],
    "mobileSkills": [
      {
        "skillname":"React-Native"
      },
      {
        "skillname":"Cordova"
      },
      {
        "skillname":"Ionic"
      },
      {
        "skillname":"Android"
      },
      {
        "skillname":"XCode"
      },
    ],
    "courseSkills": [
      {
        "skillname":"Quantum-Computing"
      },
      {
        "skillname":"Quantum-Mechanics-1"
      },
      {
        "skillname":"Quantum-Mechanics-2"
      },
      {
        "skillname":"Directed-Research-Study-in-Physics"
      },
      {
        "skillname":"Introduction-to-Quantum-Information-Processing"
      },
      {
        "skillname":"Implementations-of-Quantum-Information-Processing"
      },
      {
        "skillname":"Graduate-Quantum-Mechanics"
      },
    ],
    "portfolio":[
      {
        "name":"Xanadu StrawberryFields",
        "description":"Strawberry Fields is a full-stack Python library for designing, simulating, and optimizing continuous variable (CV) quantum optical circuits.",
        "imgurl":"images/projects/xanadu.png",
        "link":"https://www.xanadu.ai/software/"
      },
      {
        "name":"Experiment for TED Presentation",
        "description":"Collected experimental data for TED Fellow Shohini Ghose's 2018 TED presentation.",
        "imgurl":"images/projects/ted.png",
      },
      {
        "name":"Quantum Coin Toss Game",
        "description":"An interactive implementation of the quantum coin toss challenge that pits the player against the IBMQX4 quantum computer.",
        "imgurl":"images/projects/coin.png",
      },
    ],
    "testimonials":[
      {
        "description":"Marcus has demonstrated that he is able to work on some of the most ambiguous problems I have ever seen, and provides good insights and new ideas. He also has demonstrated the ability to develop innovative software technologies, and has contributed to the design of many of the components that are part of the software.",
        "name":"Nicholas Lee-Hone, Quantum Software Engineer at Photonic Inc."
      },
      {
        "description":"Marcus is one of the most passionate and reliable people I have had the pleasure of working with. He always meets his deadlines, is a great communicator and is fun to work with. He has excellent technical skills and a proven ability to lead a project from start to finish. He has an incredibly wide skill-set that is always expanding because he is constantly learning new technologies and working on different projects.",
        "name":"Tom Robertson, Software Developer at Clearpath Inc."
      },
      {
        "description": "It was clear from the start that Marcus possessed an impressive breadth of experience and thirst for knowledge directly applicable across the full-stack spectrum of technologies we were already or about to start using. He quickly learned the domain space and related new technologies, and productively participated and collaborated in architectural design discussions of a complex financial trading platform with seasoned developer colleagues; he earned their respect. He actively took the lead and competently coordinated the UI design and development efforts by a third-party team with minimal direction and provided several demonstrable deliverables.",
        "name": "Nick Foisy, VP of Engineering at DelphX Inc."
      },
      {
        "description":"When we started out all we had was a concept; an idea. It felt like we were heading nowhere with all the freelance developers. Then Marcus Edwards and his Sigma Dev came along, and turned our dream of a fully fledged app into reality. Their ability to listen, their flexibility, their empathy all add up to make them the ideal team to work with.",
        "name":"Francis Xavier Ssempiira, CEO at Yata Technologies Inc."
      },
      {
        "description":"Marcus is a strong team player. He contributed greatly to the user interface of our application and showed himself to be very adept with React. He designed a SASS framework that has set us up for easy application styling. During the time he worked with us, he has been an active communicator, which proved to be useful in both ramping him up with the existing Geddy application, as well as help us progress with our UI work.",
        "name":"Aaron Liu & James White, Geddy Founders"
      },
      {
        "description":"Marcus is a highly skilled professional who can easily create user friendly and scalable applications using the latest technologies. His knowledge of all aspects of software development, from business decisions to system architecture to dev ops, is astonishing and is highly valued by myself and all members of our DelphX team. These abilities make Marcus a great team member who is capable of helping in all aspects of the organization. It's a pleasure working with you Marcus!",
        "name":"Chad Vance, Senior Software Developer at DelphX Inc."
      },
      {
        "description":"We	put Marcus	into	an	active	Laravel	project	and	put	demands	on	him	to	work	with	somewhat	complicated	         components	in	both	PHP	and	Javascript.	He	was	a	quick	learner	and	took good advantage of	available	         resources	with minimal	assistance	needed.	His	technical	skills,	creativity,	and	technical	agility	was well	         demonstrated	during	his time	here.	His	performance	was	impressive	and	was	instrumental	in	helping	us	         deliver	our	project	in	demanding	conditions. If	you	are	considering	Marcus	for	a	development	positon	I	         believe	he	would	be	a	good	fit.",
        "name":"Brendan Farr-Gaynor, Managing Partner at Olio Digital Labs"
      }
    ]
  }
  
  export default resumeData;