import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import NpmStats from './NpmStats.js'
import 'react-tabs/style/react-tabs.css';

export default  class Resume extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="resume">

          <div className="row software">
            <div className="three columns header-col">
              <h1><span>Quantum Software</span></h1>
            </div>

            <div className="nine columns main-col">
              {
                resumeData.software && resumeData.software.map((item) => {
                  return(
                    <div className="row item">
                      <div className="twelve columns">
                          <h3>{item.name}</h3>
                          <p className="info">
                          {item.affiliation}
                          <span>&bull;</span><a href={item.link}>{item.linkName}</a>{ item.additionalInfoLink && (<span><span>&bull;</span><a href={item.additionalInfoLink}>{item.additionalInfoName}</a></span>)}
                          </p>
                          <p>
                          {item.description}
                          </p>
                          { item.package && (<NpmStats package={item.package}></NpmStats>) }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className="row research">
            <div className="three columns header-col">
              <h1><span>Research</span></h1>
            </div>

            <div className="nine columns main-col">
              {
                resumeData.research && resumeData.research.map((item) => {
                  return(
                    <div className="row item">
                      <div className="twelve columns">
                          <h3>{item.name}</h3>
                          <p className="info">
                          {item.affiliation}
                          { item.link && (<span><span>&bull;</span><a href={item.link}>{item.linkName}</a></span>)}{ item.additionalInfoLink && (<span><span>&bull;</span><a href={item.additionalInfoLink}>{item.additionalInfoName}</a></span>)}
                          </p>
                          <p>
                          {item.description}
                          </p>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className="row education">

            <div className="three columns header-col">
               <h1><span>Education</span></h1>
            </div>

            <div className="nine columns main-col">
              {
                resumeData.education && resumeData.education.map((item)=>{
                  return(
                    <div className="row item">
                       <div className="twelve columns">
                          <h3>{item.UniversityName}</h3>
                          <p className="info">
                          {item.specialization}
                          <span>&bull;</span><em className="date">{item.MonthOfStarting} {item.YearOfStarting}</em> - <em className="date">{item.MonthOfPassing} {item.YearOfPassing}</em></p>
                          <p>
                          {item.Achievements}
                          </p>
                       </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className="row work">
              <div className="three columns header-col">
                <h1><span>Experience</span></h1>
              </div>

              <div className="nine columns main-col">
                {
                  resumeData.work && resumeData.work.map((item) => {
                    return(
                      <div className="row item">
                        <div className="twelve columns">
                            <h3>{item.CompanyName}</h3>
                            <p className="info">
                            {item.specialization}
                            <span>&bull;</span><em className="date">{item.MonthOfStarting} {item.YearOfStarting}</em> - <em className="date">{item.MonthOfLeaving} {item.YearOfLeaving}</em></p>
                            <p>
                            {item.Achievements}
                            </p>
                        </div>

                      </div>

                    )
                  })
                }
              </div> 
          </div>


          <div className="row skill">

              <div className="three columns header-col">
                <h1><span>Skills</span></h1>
              </div>

              <div className="nine columns main-col">

                <p>
                {resumeData.skillsDescription}
                </p>

              <div className="bars">

              <Tabs>
                <TabList>
                  <Tab>Courses</Tab>
                  <Tab>Quantum</Tab>
                  <Tab>Frontend</Tab>
                  <Tab>Backend</Tab>
                  <Tab>Database</Tab>
                  <Tab>Embedded</Tab>
                  <Tab>Mobile</Tab>
                  <Tab>Distributed Computing</Tab>
                </TabList>
                <TabPanel>
                  <ul className="skills">
                      {
                        resumeData.courseSkills && resumeData.courseSkills.map((item) => {
                          return(
                            <li>
                            <span className={`bar-expand ${item.skillname.toLowerCase()}`}>
                            </span><em>{item.skillname.replace(/-/g,' ')}</em>
                            </li>
                          )
                        })
                      }

                  </ul>
                  <h5 class="centered">For more course grades, see my undergraduate and graduate transcripts.</h5>
                  <a href="https://github.com/comp-phys-marc/comp-phys-marc.github.io/blob/main/files/Transcripts.pdf"><button class="centered">Transcript</button></a>
                </TabPanel>
                <TabPanel>
                  <ul className="skills">
                      {
                        resumeData.quantumSkills && resumeData.quantumSkills.map((item) => {
                          return(
                            <li>
                            <span className={`bar-expand ${item.skillname.toLowerCase()}`}>
                            </span><em>{item.skillname}</em>
                            </li>
                          )
                        })
                      }

                  </ul>
                </TabPanel>
                <TabPanel>
                  <ul className="skills">
                      {
                        resumeData.frontendSkills && resumeData.frontendSkills.map((item) => {
                          return(
                            <li>
                            <span className={`bar-expand ${item.skillname.toLowerCase()}`}>
                            </span><em>{item.skillname}</em>
                            </li>
                          )
                        })
                      }

                  </ul>
                </TabPanel>
                <TabPanel>
                  <ul className="skills">
                      {
                        resumeData.backendSkills && resumeData.backendSkills.map((item) => {
                          return(
                            <li>
                            <span className={`bar-expand ${item.skillname.toLowerCase()}`}>
                            </span><em>{item.skillname}</em>
                            </li>
                          )
                        })
                      }

                  </ul>
                </TabPanel>
                <TabPanel>
                  <ul className="skills">
                      {
                        resumeData.databaseSkills && resumeData.databaseSkills.map((item) => {
                          return(
                            <li>
                            <span className={`bar-expand ${item.skillname.toLowerCase()}`}>
                            </span><em>{item.skillname}</em>
                            </li>
                          )
                        })
                      }

                  </ul>
                </TabPanel>
                <TabPanel>
                  <ul className="skills">
                      {
                        resumeData.embeddedSkills && resumeData.embeddedSkills.map((item) => {
                          return(
                            <li>
                            <span className={`bar-expand ${item.skillname.toLowerCase()}`}>
                            </span><em>{item.skillname}</em>
                            </li>
                          )
                        })
                      }

                  </ul>
                </TabPanel>
                <TabPanel>
                  <ul className="skills">
                      {
                        resumeData.mobileSkills && resumeData.mobileSkills.map((item) => {
                          return(
                            <li>
                            <span className={`bar-expand ${item.skillname.toLowerCase()}`}>
                            </span><em>{item.skillname}</em>
                            </li>
                          )
                        })
                      }

                  </ul>
                </TabPanel>
                <TabPanel>
                  <ul className="skills">
                      {
                        resumeData.distributedSkills && resumeData.distributedSkills.map((item) => {
                          return(
                            <li>
                            <span className={`bar-expand ${item.skillname.toLowerCase()}`}>
                            </span><em>{item.skillname}</em>
                            </li>
                          )
                        })
                      }

                  </ul>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>  
      </section>
    );
  }
}